<template>
  <b-card>
    <b-row>
      <div class="col-12 mt-16">
        <b-form @submit.prevent="addUser" v-if="show">
          <!-- <h2>Car Information</h2> -->
          
          <div
            style="background-color: #0010f7; height: 40px; border-radius: 4px"
          >
            <h5
              style="
                color: white;
                margin-left: 5px;
                font-weight: bold;
                padding: 10px;
                text-align: center;
              "
            >
              Company Information
            </h5>
          </div>
          
          <div class="row" style="margin-top: 20px">
            <!-- <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Company Name:"
                label-for="company_name"
              >
                <b-form-input
                  id="company_name"
                  type="text"
                  placeholder="Enter comapany name"
                  v-model="company_name"
                  required
                ></b-form-input>
              </b-form-group>
            </div> -->
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Company Name:"
                label-for="company_name"
              >
                <b-form-input
                  id="company_name"
                  type="text"
                  placeholder="Enter company name"
                  v-model="company_name"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
           
            <!-- <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label=" Organization Name:"
                label-for="owner_name"
              >
                <b-form-input
                  id="owner_name"
                  type="text"
                  placeholder="Enter organization name"  pattern="[A-Za-z]+" title="Please enter only alphabetic characters"
                  v-model="owner_name"
                  required
                ></b-form-input>
              </b-form-group>
            </div> -->
            <!-- <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label=" Organization Number:"
                label-for="owner_number"
              >
                <b-form-input
                  id="owner_number"
                  type="text"
                  placeholder="Enter organization number"
                  v-model="owner_number"
                  required
                ></b-form-input>
              </b-form-group>
            </div> -->
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Organization Number:"
                label-for="owner_number"
              >
                <b-form-input
                  id="owner_number"
                  type="number"
                  placeholder="Enter organization number"
                  pattern="[0-9]+"
                  title="Please enter only numeric characters"
                  v-model="company_number"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-2"
                label="Category:"
                label-for="category"
              >
                <b-form-select id="category" v-model="category" required>
                  <option value="">Select category</option>
                  <option>Fleet</option>
                  <option>Other</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Vat Number:"
                label-for="vat_number"
              >
                <b-form-input
                  id="vat_number"
                  type="number"
                  placeholder="Enter vat number"
                  v-model="vat_number"
                  pattern="[0-9]+"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Phone Number:"
                label-for="company_phone"
              >
                <b-form-input
                  id="company_phone"
                  type="number"
                  placeholder="Enter phone number"
                  v-model="company_phone"
                  pattern="[0-9]+"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-1" label="Email:" label-for="email">
                <b-form-input
                  id="email"
                  type="text"
                  placeholder="Enter email"
                  v-model="company_email"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Admin Fee:"
                label-for="owner_number"
              >
                <b-form-input
                  id="owner_number"
                  type="number"
                  placeholder="Enter admin fee %"
                  pattern="[0-9]+"
                  title="Please enter only numeric characters"
                  v-model="admin_fee"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="company_address:"
                label-for="company_address"
              >
                <b-form-input
                  id="company_address"
                  type="text"
                  placeholder="Enter Company Address"
                  v-model="company_address"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-12">
            <b-form-group
                id="input-group-2"
                label="Upload Vehicle Documents:"
                label-for="registration_number"
              >
              <div style="margin-left: 3px; margin-bottom: 15px">
                <input
                  type="file"
                  accept="image/*"
                  id="document[]"
                  multiple
                  @change="onCompanyLogoChange"
                  required
                />
              </div>
            </b-form-group>
            </div>
              <!-- <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Company logo:"
                label-for="company_document"
                :state="companyLogoState"
              >
                <div style="margin-left: 3px; margin-bottom: 15px">
                  <input
                    type="file"
                    accept="image/*"
                    id="company_document"
                    @change="onCompanyLogoChange"
                    required
                  />
                </div>
                <b-form-invalid-feedback :state="companyLogoState">
                  Company Logo is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </div> -->
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Company Documents:"
                label-for="company_document[]"
              >
                <div style="margin-left: 3px; margin-bottom: 15px">
                  <input
                    type="file"
                    accept="image/*"
                    id="company_document[]"
                    @change="onCompanyDocumentChange"
                    required
                    multiple
                  />
                </div>
                
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Contract Document:"
                label-for="contract_document"
                :state="companyContractState"
              >
                <div style="margin-left: 3px; margin-bottom: 15px">
                  <input
                    type="file"
                    accept="image/*"
                    id="contract_document"
                    @change="onCompanyContractChange"
                    required
                  />
                </div>
                <b-form-invalid-feedback :state="companyDocumentState">
                  Company Contract is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <!-- <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Company Document:"
                label-for="company_document"
              >
                <div style="margin-left: 3px; margin-bottom: 15px">
                  <input
                    type="file"
                    accept="image/*"
                    id="company_document"
                    @change="onCompanyDocumentChange"
                  />
                </div>
              </b-form-group>
            </div> -->
          </div>

          <div
            style="background-color: #0010f7; height: 40px; border-radius: 4px"
          >
            <h5
              style="
                color: white;
                margin-left: 5px;
                font-weight: bold;
                padding: 10px;
                text-align: center;
              "
            >
              Owner Details
            </h5>
          </div>
          <div class="row" style="margin-top: 20px">
             <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Company Owner Name:"
                label-for="owner_name"
              >
                <b-form-input
                  id="owner_name"
                  type="text"
                  placeholder="Enter company Owner name"
                  v-model="owner_name"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
             <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Phone Number:"
                label-for="owner_number"
              >
                <b-form-input
                  id="owner_number"
                  type="text"
                  placeholder="Enter phone number"
                  v-model="owner_number"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group id="input-group-1" label="Email:" label-for="email">
                <!-- <b-form-input
                  id="email"
                  type="text"
                  placeholder="Enter email"
                  v-model="email"
                  required
                ></b-form-input> -->
                <!-- email validation  -->
                <b-form-input
                  id="email"
                  type="email"
                  placeholder="Enter email"
                  v-model="owner_email"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4 col-12">
              <b-form-group
                id="input-group-1"
                label="Address:"
                label-for="address"
              >
                <b-form-input
                  id="address"
                  type="text"
                  placeholder="Enter address"
                  v-model="owner_address"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div
            style="background-color: #0010f7; height: 40px; border-radius: 4px"
          >
            <h5
              style="
                color: white;
                margin-left: 5px;
                font-weight: bold;
                padding: 10px;
                text-align: center;
              "
            >
              Bank Details
            </h5>
          </div>
          <div class="row" style="margin-top: 20px">
            
            <div class="col-md-4 col-12">
                <b-form-group id="input-group-1" label="Bank Name:" label-for="bank_account_holder_name">
                  <b-form-input id="bank_name" type="text" placeholder="Enter bank name"
                    v-model="bank_name" pattern="[A- Z a-z]+" title="Please enter only alphabetic characters"
                    ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4 col-12">
                <b-form-group id="input-group-2" label="Bank Account Number:" label-for="bank_account_number">
                  <b-form-input id="bank_account_number" placeholder="Enter bank account number"
                    v-model="bank_account_number" pattern="[0-9]+" title="Please enter only numeric characters"
                    ></b-form-input>
                </b-form-group>
              </div>
          </div>
          <div
            style="background-color: #0010f7; height: 40px; border-radius: 4px"
          >
            <h5
              style="
                color: white;
                margin-left: 5px;
                font-weight: bold;
                padding: 10px;
                text-align: center;
              "
            >
              Additional Information
            </h5>
          </div>
          <div class="row" style="margin-top: 20px">
            
            <div class="col-md-12 col-12">
              <b-form-group
                id="input-group-1"
                label="Report:"
                label-for="report"
              >
              <b-form-textarea
                v-model="report"
                placeholder="Enter Report"
                required
              >
              </b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <b-button
            type="submit"
            variant="primary"
            class="mb-8 mr-8"
            :disabled="isLoading"
          >
            <span v-if="!isLoading">Submit</span>
            <b-spinner
              v-else
              class="mb-8 mr-8"
              variant="primary"
              small
            ></b-spinner>
          </b-button>
        </b-form>
      </div>

      <div
        v-if="codeActive"
        class="col-12 mt-24 hljs-container"
        :class="{ active: codeActiveClass }"
      >
        <pre v-highlightjs>
              <code class="hljs html">
                {{ codeText }}
              </code>
            </pre>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormSelect,
  BFormInput,
  BSpinner,
  BFormTextarea
} from "bootstrap-vue";
import axios from "axios";
import code from "../components/data-entry/form/code";
import { BToast } from "bootstrap-vue";
export default {
  data() {
    return {
      company_name: "",
      companyDocumentState: null,
      companyLogoState: null,
      companyContractState: null,
      selectedType: "",
      show: true,
      codeText: code.introduction,
      codeActive: false,
      codeActiveClass: false,
      isLoading: false,
      // new code
      // Car Maintenance
      owner_name: "",
      owner_number: "",
      company_document: [],
      company_name: "",
      category: "",
      owner_email:"",
      owner_number:"",
      owner_address:"",
      vat_number:"",
      company_phone:"",
      company_email:"",
      company_address:"",
      contract:"",
      company_number:"",
      admin_fee: "",
      report: "",
      bank_name: "",
      bank_account_number: "",
      logo: "",

    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BToast,
    BSpinner,
    BFormTextarea
  },
  methods: {
    onCompanyDocumentChange(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        // Convert FileList to an array
        this.company_document = Array.from(files);
        console.log(this.company_document);
      }
    },
    onCompanyContractChange(event) {
      // Check if a file is selected
      this.companyContractState = event.target.files.length > 0 ? true : false;
    },
    // onCompanyLogoChange(event) {
    //   // Check if a file is selected
    //   this.companyLogoState = event.target.files.length > 0 ? true : false;
     
    // },

    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
      // debugger
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //    Add Vehicle
    addUser() {
      this.isLoading = true;
      // Create a FormData object to handle the image file
      const formData = new FormData();
      formData.append("owner_name", this.owner_name);
      // formData.append("company_document", this.company_document);
      for (const documents of this.company_document) {
        formData.append("company_document[]", documents);
      }
      formData.append("company_name", this.company_name);
      formData.append("category", this.category);
      formData.append("owner_email", this.owner_email);
      formData.append("owner_number", this.owner_number);
      formData.append("owner_address", this.owner_address);
      formData.append("vat_number", this.vat_number);
      formData.append("company_phone", this.company_phone);
      formData.append("company_email", this.company_email);
      formData.append("company_address", this.company_address);
      formData.append("contract", this.contract);
      formData.append("company_number", this.company_number);
      formData.append("admin_fee", this.admin_fee);
      formData.append("report", this.report);
      formData.append("bank_name", this.bank_name);
      formData.append("bank_account_number", this.bank_account_number);
      formData.append("logo", this.logo);

      axios
        .post("company", formData)
        .then((response) => {
          console.log(response.data);
          this.$bvToast.toast("company added successfully!", {
            title: "Success",
            variant: "success",
            solid: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            autoHideDelay: 5000,
            variant: "primary", // Background color
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          console.log(error.response.data);
          this.isLoading = false;
        });
    },

    codeClick() {
      this.codeActive = !this.codeActive;

      setTimeout(() => {
        this.codeActiveClass = !this.codeActiveClass;
      }, 100);
    },

    // onCompanyDocumentChange(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.company_document = file;
    //   }
    // },
    onCompanyContractChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.contract = file;
      }
    },
    onCompanyLogoChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.logo = file;
      }
    },
  },
};
</script>
